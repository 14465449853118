import React from 'react';

import {Link} from "react-router-dom";
import loader from '../css/image/loader.gif';

// Komponent
import Carusel from '../components/carusel';
import Galerie from "../components/galerie";

class Start extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loaded: 0
        }

    }

    imageLoaded = () => {
        this.setState({ loaded: this.state.loaded + 1 });
    }

    createPhotos(data) {
        let photos = [];
        for (let i = 0; i < data.length; i++) {
            let item = {
                src: data[i],
                width: 3,
                height: 4
            }
            photos.push(item);
        }
        return photos;
    }


    render() {

        const { loaded } = this.state;
        const { images } = this.props;
        const { top = [], carusel = [], gallery = [] } = images || {};
        let photos = this.createPhotos(gallery);
        const loaderActive = loaded < 3;

        return (<>

            {loaderActive ? <>
                    <div id="loader">
                        <img src={loader} alt="" width="300px" height="auto"/>
                    </div>
                </>
                : ""}
            <div className="ff-2 indexDiv"
                 style={{ visibility: loaderActive ? "hidden" : "visible" }}>

                <div className="startBlock1 block-small mt-3 ">
                    <div className="container">
                        <div className="row m-0 startBildDiv ">
                            <div className="col-4 p-1">
                                <img src={top[0]} alt="" width="100%" height="auto"
                                     onLoad={this.imageLoaded} onError={this.imageLoaded}/>
                            </div>
                            <div className="col-4 p-1">
                                <img src={top[1]} alt="" width="100%" height="auto"
                                     onLoad={this.imageLoaded} onError={this.imageLoaded}/>
                            </div>
                            <div className="col-4 p-1">
                                <img src={top[2]} alt="" width="100%" height="auto"
                                     onLoad={this.imageLoaded} onError={this.imageLoaded}/>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="block-small">
                    <div className="container">
                        <div className="row m-0  ">
                            <div className="col-12 text-center ff-1">
                                <h2>Welcome to our official website</h2>
                            </div>
                            <div className="col-12 mt-3 d-flex">
                                <p className="m-auto text-center">
                                    Please click here to learn more about our company, its services,
                                    prices and staff
                                </p>
                            </div>
                            <div className="mt-5 text-center col-12">
                                <Link to="/about">
                                    <button className="startButton">know more</button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>

                <div className=" mb-5">
                    <Carusel bilder={carusel}
                             height={this.props.mobileVersion ? "300px" : "500px"}
                             width="100%"/>
                </div>

                <div className="row m-0">
                    <div className="col-12 text-center ff-3">
                        <h1>OUR SERVICES</h1>
                    </div>
                </div>


                <div className="container mt-5 mb-5">
                    <div className="row m-0">

                        <div className="col-12 col-md-6 text-center mt-5 border-right">
                            <h3 className=" letter-spacing">Photography</h3>
                            <p>Please click here ( Learn more ) to see the Photos made by
                                LunaStudio</p>
                            <Link to="/photo">
                                <button className="startButton mt-2">know more</button>
                            </Link>
                        </div>


                        <div className="col-12 col-md-6  text-center mt-5 border-left">
                            <h3 className="letter-spacing">Videography</h3>
                            <p>Please click here ( Learn more ) to see Videos made by LunaStudio</p>
                            <Link to="/video">
                                <button className="startButton mt-2">know more</button>
                            </Link>
                        </div>
                    </div>
                </div>

                <div style={{ marginTop: "150px" }}>
                    <div className="row m-0">
                        <div className="col-12 text-center ff-3">
                            <h1>GALLERY</h1>
                        </div>
                    </div>
                </div>

                <div className="mt-5 mb-5">
                    <div className="container">

                        <Galerie photos={photos}/>


                    </div>
                </div>

            </div>

        </>);
    }
}

export default Start;