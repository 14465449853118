import React from 'react';


import loader from '../css/image/loader.gif';
// Komponent
import FacebookIcon from '@material-ui/icons/Facebook';
import YouTubeIcon from '@material-ui/icons/YouTube';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import InstagramIcon from '@material-ui/icons/Instagram';
import CallIcon from '@material-ui/icons/Call';
import ForumIcon from '@material-ui/icons/Forum';

const Facebook = "https://www.facebook.com/LunaStudioAM";
const insta = "https://www.instagram.com/lunastudio.am/";
const whatsapp = "https://wa.me/+37477557027";


class Start extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: true
        }

    }

    closeLoader() {
        this.setState({ loader: false });

    }

    componentDidMount() {
        setTimeout(() => {
            this.closeLoader();
        }, 1000);

    }



    render() {
        return (<>

            {this.state.loader ? <>
                <div id="loader">
                    <img src={loader} alt="" width="300px" height="auto" />
                </div>

            </>
                : ""}
            <div className="ff-2 mb-5 container" style={{ visibility: this.state.loader ? "hidden" : "visible" }}>

                <div className="row m-0">
                    <h2 className="col-12 ff-3 text-center mt-5">CONTACT</h2>
                </div>


                <div className="row m-0 text-center mt-5 ">

                    <div className="col-md-6 col-12 mt-5 d-flex flex-column">
                        <div className="ml-auto mr-auto mt-3">
                            <CallIcon style={{ fontSize: "40pt", color: "#484848" }} />
                            <h3 className="mt-2">Phone</h3>
                        </div>

                        <div className="m-auto">
                            <p className="m-0 mt-3">Mobil: 077 557 027</p>
                            <p className="m-0 mt-2">E-Mail: luna.videographers@gmail.com</p>
                            {/*<p className="m-0 mt-2">Mesrop Mashtots Street 15, Armavir, Armenia</p>*/}
                        </div>

                    </div>

                    <div className="col-md-6 col-12 mt-5 d-flex flex-column">
                        <div className="ml-auto mr-auto mt-3">
                            <ForumIcon style={{ fontSize: "40pt", color: "#484848" }} />
                            <h3 className="mt-2">Chat with us</h3>
                        </div>

                        <div className="m-auto">
                            <div className=" text-center mt-2 d-flex footerIconsDiv">
                                <div className="ml-auto">
                                    <span onClick={() => window.open(Facebook, "_blank")}>   <FacebookIcon style={{ fontSize: "35pt", color: "#4267b2" }} /></span>
                                </div>
                                <div className="ml-3 mr-3 border-left border-right pl-3 pr-3">
                                    <span onClick={() => window.open(whatsapp, "_blank")}>  <WhatsAppIcon style={{ fontSize: "35pt", color: "#25D366" }} /></span>
                                </div>
                                <div className="mr-auto">
                                    <span onClick={() => window.open(insta, "_blank")}>  <InstagramIcon style={{ fontSize: "35pt", color: "#E4405F" }} /></span>
                                </div>
                            </div>
                        </div>


                    </div>



                    <div style={{ marginTop: "150px", marginBottom: "100px" }}>
                        <h3 className="ff-1 text-secondary"><b>Mail</b></h3>
                        <form>
                            <div className="mt-5">
                                <input className="contactInput" placeholder="E-Mail" type="email" />
                            </div>
                            <div className="mt-2">
                                <input className="contactInput" placeholder="Title" />
                            </div>
                            <div className="mt-2">
                                <textarea
                                    className="contactInput"
                                    placeholder="Text"
                                    row="8" cols="500"
                                    style={{ minHeight: "150px", resize: "none" }}
                                />
                            </div>
                            <button className="contactSendButton">Send</button>

                        </form>
                    </div>

                 {/*   <div className="mt-5" style={{ width: "100%", marginTop: "150px", marginBottom: "150px" }}>
                        <iframe title="Luna Studio"
                            width="100%"
                            height="400"
                            frameborder="0"
                            scrolling="no"
                            marginheight="0"
                            marginwidth="0"
                            src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=40%C2%B009'15.5%22N%2044%C2%B001'37.1%22E+(Li%C3%BAna%20Studio)&amp;t=p&amp;z=15&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                        />
                    </div>*/}

                </div>





            </div >

        </>);
    }
}

export default Start;
