import React from 'react';

import Team from '../css/image/about/Team.jpg';
import Narek from '../css/image/about/Narek.jpg';
import Lian from '../css/image/about/Lian.jpg';
import Eliz from '../css/image/about/Eliz.jpg';
import Saqo from '../css/image/about/Saqo.jpg';
import Arman from '../css/image/about/Arman.jpg';
import Minas from '../css/image/about/Minas.jpg';
import "../css/about.css";

import loader from '../css/image/loader.gif';


class Start extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: true
        }

    }

    closeLoader() {
        this.setState({ loader: false });

    }

    componentDidMount() {
        setTimeout(() => {
            this.closeLoader();
        }, 1000);

    }


    render() {
        const { logos = [] } = this.props
        return (<>

            {this.state.loader ? <>
                    <div id="loader">
                        <img src={loader} alt="" width="300px" height="auto"/>
                    </div>

                </>
                : ""}
            <div className="about ff-2 mb-5"
                 style={{ visibility: this.state.loader ? "hidden" : "visible" }}>


                <div className="container mt-5 mb-5" style={{ padding: 0 }}>
                    <div className="row m-0">
                        <div className="col-12 text-center ff-3 text-secondary">
                            <h2>HISTORY</h2>
                        </div>
                        <div className="col-12 text-center mt-4">
                            <p>
                                LunaStudio, founded in 2020, is specialized in professional Photo
                                and Video services.
                                We are a full service production company that offers both
                                Photography and Videography services to our clients.
                                We really listen to our clients needs and strive to do everything to
                                satisfy our clients with the end result.
                            </p>
                        </div>

                      {/*  <div className="col-12 text-center">
                            <img src={Team} alt="" width="100%" height="auto"
                                 style={{ maxHeight: "650px" }} className="cover"/>
                        </div>*/}
                        <div className="col-12 text-center mt-4">
                            <p>
                                We'll always be in contact with you either online and by phone.We
                                work with the following principle:
                                every trifle is important in our work.
                            </p>
                        </div>

                        <div className="col-12 mt-4">
                            <hr/>
                        </div>


                 {/*       <div className="col-12 text-center ff-3 mb-5 text-secondary"
                             style={{ marginTop: "100px" }}>
                            <h2>STAFF</h2>
                        </div>

                        <div className="col-12 col-md-6 m-0 row mt-5 border-right border-left">
                            <div className="col-5 ">
                                <img src={Narek} alt="" width="100%" height="auto"/>
                            </div>
                            <div className="col-7  d-flex info-wrapper">
                                <div className="d-flex flex-column ">
                                    <h4 className="">Narek</h4>
                                    <p className="mt-auto mb-auto text">Born on: 16.08.1997</p>
                                    <p className="mt-auto mb-auto text">Founding Director</p>
                                    <span className="mt-auto text">Camera: Sony 7 III</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 m-0 row mt-5 border-right border-left">
                            <div className="col-5 ">
                                <img src={Minas} alt="" width="100%" height="auto"/>
                            </div>
                            <div className="col-7  d-flex info-wrapper">
                                <div className="d-flex flex-column ">
                                    <h4 className="">Minas</h4>
                                    <p className="mt-auto mb-auto text">Born on: 07.09.1995</p>
                                    <p className="mt-auto mb-auto text">Photographer</p>
                                    <span className="mt-auto text">Camera: Canon EOS R</span>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-md-6 m-0 row mt-5 border-right border-left">
                            <div className="col-5 ">
                                <img src={Lian} alt="" width="100%" height="auto"/>
                            </div>
                            <div className="col-7  d-flex info-wrapper">
                                <div className="d-flex flex-column ">
                                    <h4 className="">Liana</h4>
                                    <p className="mt-auto mb-auto text">Born on: 16.02.2000</p>
                                    <p className="mt-auto mb-auto text">Bride photographer</p>
                                    <span className="mt-auto text">Camera : Sony 7 III</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 m-0 row mt-5 border-right border-left">
                            <div className="col-5 ">
                                <img src={Eliz} alt="" width="100%" height="auto"/>
                            </div>
                            <div className="col-7  d-flex info-wrapper">
                                <div className="d-flex flex-column ">
                                    <h4 className="">Eliz</h4>
                                    <p className="mt-auto mb-auto text">Born on: 20.07.1998</p>
                                    <p className="mt-auto mb-auto text">Reserve Photographer</p>
                                    <span className="mt-auto text">Camera : Sony 7 III</span>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-md-6 m-0 row mt-5 border-right border-left">
                            <div className="col-5 ">
                                <img src={Arman} alt="" width="100%" height="auto"/>
                            </div>
                            <div className="col-7  d-flex info-wrapper">
                                <div className="d-flex flex-column ">
                                    <h4 className="">Arman</h4>
                                    <p className="mt-auto mb-auto text">Born on: 17.06.2006</p>
                                    <p className="mt-auto mb-auto text">Second Videographer</p>
                                    <span className="mt-auto text">Camera : FX3</span>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-md-6 m-0 row mt-5 border-right border-left">
                            <div className="col-5 ">
                                <img src={Saqo} alt="" width="100%" height="auto"/>
                            </div>
                            <div className="col-7  d-flex info-wrapper">
                                <div className="d-flex flex-column ">
                                    <h4 className="">Saqo</h4>
                                    <p className="mt-auto mb-auto text">Born on: 04.02.1996</p>
                                    <p className="mt-auto mb-auto text">Reserve Videographer</p>
                                    <span className="mt-auto text">Camera : Sony A6400</span>
                                </div>
                            </div>
                        </div>
                        */}


                    </div>
                </div>


                <div className=" ff-3 mb-5 text-secondary container" style={{ marginTop: "100px" }}>

                    <h2 className="text-center">SERVICES AND PRICE LIST</h2>
                    <div className="row m-0 text-center">


                        <div className="price-list col-md-4 col-12 mt-5">
                            <h3>STANDART</h3>
                            <p className="m-0">1 Photo + 1 Video + Drone - 200.000 AMD</p>
                            <p className="m-0">1 Photo + 2 Video + Drone - 300.000 AMD</p>
                            <p className="m-0">2 Photo + 2 Video + Drone - 400.000 AMD</p>
                            <p className="m-0">2 Photo + 2 Video + Cabelcam + Drone - 450.000 AMD</p>
                            <p className="m-0">2 Photo + 3 Video + Cabelcam + Drone - 500.000 AMD</p>
                            <p className="mt-3">The package includes:</p>
                            <div className="package mt-3">
                                <p className="m-0">- Wedding Day</p>
                            </div>
                        </div>
                        <div className="price-list col-md-4 col-12 mt-5">
                            <h3>PREMIUM</h3>
                            <p className="m-0">1 Photo + 1 Video + Drone - 300.000 AMD</p>
                            <p className="m-0">1 Photo + 2 Video + Drone - 400.000 AMD</p>
                            <p className="m-0">2 Photo + 2 Video + Drone - 500.000 AMD</p>
                            <p className="m-0">2 Photo + 2 Video + Cabelcam + Drone - 550.000 AMD</p>
                            <p className="m-0">2 Photo + 3 Video + Cabelcam + Drone - 600.000 AMD</p>
                            <p className="mt-3">The package includes:</p>
                            <div className="package mt-3">
                                <p className="m-0">- Wedding Day</p>
                                <p className="m-0">- PreWedding Photo</p>
                            </div>
                        </div>
                        <div className="price-list col-md-4 col-12 mt-5">
                            <h3>VIP</h3>
                            <p className="m-0">1 Photo + 1 Video + Drone - 400.000 AMD</p>
                            <p className="m-0">1 Photo + 2 Video + Drone - 500.000 AMD</p>
                            <p className="m-0">2 Photo + 2 Video + Drone - 600.000 AMD</p>
                            <p className="m-0">2 Photo + 2 Video + Cabelcam + Drone - 650.000 AMD</p>
                            <p className="m-0">2 Photo + 3 Video + Cabelcam + Drone - 700.000 AMD</p>

                            <p className="mt-3">The package includes:</p>
                            <div className="package mt-3">
                                <p className="m-0">- WeddingDay</p>
                                <p className="m-0">- PreWedding Photo / Video</p>
                                <p className="m-0">- Same Day Edit Video</p>
                            </div>
                        </div>

                        {/*<div className="col-md-4 col-12 mt-5">
                            <h3>EVENTS</h3>
                            <p className="m-0">1 Photographer - 30000 AMD</p>
                            <p className="m-0">1 Videographer - 50000 AMD</p>
                            <p className="m-0">1 Photo + 1 Video - 80000 AMD</p>
                            <p className="m-0">1 Photo + 1 Video + Drone - 100000 AMD</p>
                            <p className="m-0">2 Photos + 2 Videos + Drone - 150000 AMD <br/>( Free
                                Prewedding Photo Shoot )</p>
                            <p className="m-0">2 Photos + 2 Videos + Crane + Drone - 180000
                                AMD <br/>( Free Prewedding Photo Shoot )</p>
                            <p className="m-0">2 Photos + 3 Videos + Crane + Drone - 230000
                                AMD <br/>( Free Prewedding Photo Shoot )</p>
                        </div>

                       <div className="col-md-4 col-12 mt-5">
                            <h3>CORPORATES</h3>

                            <p className="m-0">1 Photographer 30000 AMD or one hour 10000 AMD</p>
                            <p className="m-0">1 Videographer 50000 or one hour 20000 AMD</p>
                            <h3 className="mt-5">PRODUCT PHOTOGRAPHY</h3>
                            <p className="m-0">1 Product - 500 AMD</p>
                            <p className="m-0">100+ Products - 300 AMD</p>
                            <p className="m-0">300+ Products - 200 AMD</p>
                        </div>
                        <div className="col-md-4 col-12 mt-5">
                            <h3>STUDIO PHOTOGRAPHY</h3>
                            <h4 className="mt-4">KIDS</h4>
                            <p className="m-0">1 Photo 1000 AMD</p>
                            <p className="m-0">5 Photos 3500 AMD</p>
                            <p className="m-0">10 Photos 6000 AMD</p>
                            <h4 className="mt-4">FAMILY</h4>
                            <p className="m-0">1 Photo - 1500 AMD</p>
                            <p className="m-0">One hour Photoshoot - 15000 AMD</p>
                        </div>*/}

                    </div>

                </div>


                {/*<div className="container" style={{ marginTop: "150px" }}>
                    <div className="row m-0">
                        <div className="col-12 text-center ">
                            <h2 className="ff-3 text-secondary">COOPERATION</h2>
                            <p className="mt-3">Our company also cooperates with the following
                                organizations.</p>
                        </div>
                    </div>
                </div>
                <div className="" style={{ marginBottom: "100px" }}>
                    <div className="container">
                        <div className="row m-0 d-flex justify-content-center">
                            <a href="https://www.instagram.com/nali_hmshop/"
                               className="col-6 col-md-3 ">
                                <img src={logos[0]} alt="" width="100%" height="auto"/>
                            </a>
                            <a href="https://smartsofficial.com" className="col-6 col-md-3 ">
                                <img src={logos[1]} alt="" width="100%" height="auto"/>
                            </a>
                            <a href="https://kiddok.am/" className="col-6 col-md-3 ">
                                <img src={logos[2]} alt="" width="100%" height="auto"/>
                            </a>
                            <a href="https://rubenkaroyan.com/" className="col-6 col-md-3 ">
                                <img src={logos[3]} alt="" width="100%" height="auto"/>
                            </a>
                        </div>
                    </div>
                </div>*/}


            </div>
        </>);
    }
}

export default Start;
