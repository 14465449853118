import React from 'react';

// Komponent
import loader from '../css/image/loader.gif';
import Galerie from "../components/galerie";

class Video extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: true,
            openDialog: false,
            dialogBild: null
        }

    }

    closeLoader() {
        this.setState({ loader: false });

    }

    componentDidMount() {
        setTimeout(() => {
            this.closeLoader();
        }, 1500);

    }


    createPhotos(data) {
        let photos = [];
        for (let i = 0; i < data.length; i++) {
            let item = {
                src: data[i],
                width: Math.random() * (5 - 2) + 2,
                height: Math.random() * (8 - 2) + 2
            }
            photos.push(item);
        }
        return photos;
    }


    render() {
        const { images } = this.props;
        const { album1 = [], album2 = [] } = images || {};

        let photos1 = this.createPhotos(album1);
        let photos2 = this.createPhotos(album2);

        return (<>

            {this.state.loader ? <>
                    <div id="loader">
                        <img src={loader} alt="" width="300px" height="auto"/>
                    </div>

                </>
                : ""}

            <div className="ff-2 text-secondary mb-5"
                 style={{ visibility: this.state.loader ? "hidden" : "visible" }}>

                <div className="row m-0">
                    <h2 className="col-12 ff-3 text-center mt-5">PHOTOS</h2>
                </div>


                <div className="container mt-5">
                    <h4 className="videoVernagir text-right">Events Photos</h4>
                </div>
                <div className="container">
                    <Galerie photos={photos1}/>
                </div>

                <div className="container mt-5">
                    <h4 className="videoVernagir text-right">Studio Photos</h4>
                </div>
                <div className="container">
                    <Galerie photos={photos2}/>
                </div>

            </div>
        </>);
    }
}

export default Video;