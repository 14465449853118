import React from 'react';

import FacebookIcon from '@material-ui/icons/Facebook';
import YouTubeIcon from '@material-ui/icons/YouTube';
import InstagramIcon from '@material-ui/icons/Instagram';

const Facebook = "https://www.facebook.com/LunaStudioAM";
const insta = "https://www.instagram.com/lunastudio.am/";
const youtube = "https://www.youtube.com/channel/UCHMnWuxKAUhYhG5lB6w3LIQ";


class Footer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        return (<>
            <div className="footer ff-2 ">
                <div className="container mt-5">
                    <div className="row m-0">

                        <div className="row m-0 col-12 text-center mt-3 pt-2 border-top d-flex">
                            <div className=" col-12 col-md-6 p-0 mt-2">
                                <p className="text-center p-0 m-0">Phone: {this.props.mobileVersion ? <br /> : ""} +374 77 557027</p>
                            </div>
                            <div className=" col-12 col-md-6 p-0 mt-2">
                                <p className="text-center">E-Mail: {this.props.mobileVersion ? <br /> : ""} luna.videographers@gmail.com </p>
                            </div>
                        </div>

                        <h6 className="col-12 text-center mt-3">follow us on</h6>

                        <div className="col-12 text-center mt-2 d-flex footerIconsDiv">
                            <div className="ml-auto">
                                <span onClick={() => window.open(Facebook, "_blank")}><FacebookIcon style={{ fontSize: "25pt" }} /></span>
                            </div>
                            <div className="ml-3 mr-3 border-left border-right pl-3 pr-3">
                                <span onClick={() => window.open(youtube, "_blank")}>  <YouTubeIcon style={{ fontSize: "25pt" }} /></span>
                            </div>
                            <div className="mr-auto">
                                <span onClick={() => window.open(insta, "_blank")}>   <InstagramIcon style={{ fontSize: "25pt" }} /></span>
                            </div>
                        </div>

                        <div className="col-12 text-center mt-4 pl-5 pr-5 ">
                            <p className="border-top p-2 pt-3"><b><span className="ff-1">CREATED BY</span></b> Arman Khachatryan</p>
                        </div>

                    </div>

                </div>





            </div>
        </>);
    }
}

export default Footer;
