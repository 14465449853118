import React from 'react';
import './css/app.css';
import './css/animate.css';
import './css/bootstrap.css';
import {BrowserRouter as Router, Switch, Route, /* Link */} from "react-router-dom";

// Seiten
import Start from './seiten/index';
import About from './seiten/about';
import Contact from './seiten/contact';
import Video from './seiten/video';
import Photo from './seiten/photo';

import Navbar from './components/navbar';
import Footer from './components/footer';

/*VIP

▪ 1 Photo + 1 Video+Drone - 250.000  AMD
▪ 1 Photo + 2 Video + Drone - 280.000 AMD
▪ 2 Photo + 2 Video + Drone - 320.000 AMD
▪ 2 Photo + 2 Video + Crane + Drone - 370.000 AMD
▪ 2 Photo + 3 Video + Crane + Drone -  400.000 AMD

The package includes :
    - all designed photos on wedding day
- wedding day video on wedding day
- free prewedding video
- USB wedding video clip and photos with a wodden nominal box

PREMIUM

▪ 1 Photo + 1 Video+Drone - 190.000  AMD
▪ 1 Photo + 2 Video + Drone - 220.000 AMD
▪ 2 Photo + 2 Video + Drone - 260.000 AMD
▪ 2 Photo + 2 Video + Crane + Drone - 310.000 AMD
▪ 2 Photo + 3 Video + Crane + Drone -  340.000 AMD

The package includes:
    - free prewedding photoshoot
- USB drive with wedding clip and photos

STANDART

▪ 1 Photo + 1 Video+Drone - 130.000  AMD
▪ 1 Photo + 2 Video + Drone - 160.000 AMD
▪ 2 Photo + 2 Video + Drone - 200.000 AMD
▪ 2 Photo + 2 Video + Crane + Drone - 250.000 AMD
▪ 2 Photo + 3 Video + Crane + Drone -  280.000 AMD*/
class App extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            mobileVersion: false,
            resources: {},
        };
    }

    componentDidMount() {
        window.onresize = (e) => {
            if (e.target.innerWidth < 1000) {
                if (this.state.mobileVersion === true) {
                    return;
                }
                this.setState({ mobileVersion: true });
            } else {
                if (this.state.mobileVersion === false) {
                    return;
                }
                this.setState({ mobileVersion: false });
            }
        };

        if (window.innerWidth < 1000) {
            this.setState({ mobileVersion: true })
        }
        fetch(`./resources.json?date=${Date.now()}`)
            .then(res => res.json())
            .then(resources => {
                this.setState({
                    resources
                })
            })

    }

    render() {
        const { resources } = this.state
        return (<>
                <Router>
                    <Navbar mobileVersion={this.state.mobileVersion}/>

                    <Switch>

                        <Route exact path="/about">
                            <About mobileVersion={this.state.mobileVersion} logos={resources && resources.logos}/>
                        </Route>
                        <Route exact path="/contact">
                            <Contact mobileVersion={this.state.mobileVersion} />
                        </Route>
                        <Route exact path="/video">
                            <Video mobileVersion={this.state.mobileVersion} videos={resources && resources.videos}/>
                        </Route>
                        <Route exact path="/photo">
                            <Photo mobileVersion={this.state.mobileVersion} images={resources && resources.photos}/>
                        </Route>

                        <Route path="/">
                            <Start mobileVersion={this.state.mobileVersion} images={resources && resources.start}/>
                        </Route>

                    </Switch>

                    <Footer mobileVersion={this.state.mobileVersion}/>
                </Router>
            </>
        );
    }

}

export default App;
