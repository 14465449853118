import React from 'react';
import { AppBar, IconButton, Toolbar, Drawer, Divider } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { NavLink, Link } from "react-router-dom";

import logo from '../css/image/logo.png';
import logoM from '../css/image/logoM.png';

//icons
import InfoIcon from '@material-ui/icons/Info';
import MovieIcon from '@material-ui/icons/Movie';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import PermContactCalendarIcon from '@material-ui/icons/PermContactCalendar';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import PolicyIcon from '@material-ui/icons/Policy';
import MenuIcon from '@material-ui/icons/Menu';

const MStyle = {
    AppBar: {
        backgroundColor: "white",
        border: "none",
        boxShadow: "none",
        margin: 0,
        padding: 0
    }
};


class Navbar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false
        }
    }

    handleDrawer() {
        this.setState({ open: !this.state.open, });
    }

    render() {

        if (this.props.mobileVersion) {

            return <>
                <Drawer open={this.state.open} anchor="right" onClose={() => this.setState({ open: false })}>
                    <div className="d-flex flex-column drawerDiv">
                        <Link onClick={() => this.handleDrawer()} to="/" className=" ml-auto mr-auto mt-3 mb-2">
                            <img width="150px" height="auto" src={logo} className=" " alt="" />
                        </Link>
                        <Divider />

                        <NavLink onClick={() => this.handleDrawer()} activeClassName="active" to="/about" className="mt-3 navbarButton ">
                            <p className="ml-3 d-flex"><InfoIcon /><span className="ml-auto mr-3"> about</span></p>
                        </NavLink>

                        <NavLink onClick={() => this.handleDrawer()} activeClassName="active" to="/video" className=" navbarButton">
                            <p className="ml-3 d-flex"><MovieIcon /><span className="ml-auto mr-3"> video's</span></p>
                        </NavLink>

                        <NavLink onClick={() => this.handleDrawer()} activeClassName="active" to="/photo" className=" navbarButton">
                            <p className="ml-3 d-flex"><PhotoCameraIcon /><span className="ml-auto mr-3"> photo's</span></p>
                        </NavLink>

                        <NavLink onClick={() => this.handleDrawer()} activeClassName="active" to="/contact" className=" navbarButton">
                            <p className="ml-3 d-flex"><PermContactCalendarIcon /><span className="ml-auto mr-3"> contact</span></p>
                        </NavLink>

                        {/*<NavLink onClick={() => this.handleDrawer()} activeClassName="active" to="/contact" className=" navbarButton">
                            <p className="ml-3 d-flex"><ContactSupportIcon /><span className="ml-auto mr-3"> impressum</span></p>
                        </NavLink>
*/}
                       {/* <NavLink onClick={() => this.handleDrawer()} activeClassName="active" to="/contact" className=" navbarButton">
                            <p className="ml-3 d-flex"><PolicyIcon /><span className="ml-auto mr-3"> privacy</span></p>
                        </NavLink>*/}

                    </div>
                </Drawer>


                <AppBar position="static" className={this.props.classes.AppBar}>
                    <Toolbar>

                        <IconButton onClick={() => this.handleDrawer()} disabled className="hidden" >
                            <MenuIcon style={{ color: "rgb(48, 48, 48)", fontSize: "28pt" }} />
                        </IconButton>

                        <Link to="/" className="ml-auto mr-auto ">
                            <img width="130px" height="auto" src={logoM} className=" " alt="" />
                        </Link>

                        <IconButton onClick={() => this.handleDrawer()} >
                            <MenuIcon style={{ color: "#00000063", fontSize: "20pt" }} />
                        </IconButton>
                    </Toolbar>
                </AppBar>

            </>

        }



        return (<>
            <div className="ff-1">
                <AppBar position="static" className={this.props.classes.AppBar}>
                    <Toolbar>
                        <NavLink activeClassName="active" to="/about" className="ml-5 mr-5 mt-3 navbarButton ml-auto">about</NavLink>
                        <NavLink activeClassName="active" to="/video" className="ml-5 mr-5 mt-3 navbarButton">video's</NavLink>
                        <Link to="/" className="ml-5 mr-5 navbarButton">
                            <img width="100%" height="auto" style={{ maxWidth: "200px" }} src={logo} className="ml-5 mr-5 navbarButton navBild" alt="" />
                        </Link>
                        <NavLink activeClassName="active" to="/photo" className="ml-5 mr-5 mt-3 navbarButton">photo's</NavLink>
                        <NavLink activeClassName="active" to="/contact" className="ml-5 mr-5 mt-3 navbarButton mr-auto">contact</NavLink>
                    </Toolbar>
                </AppBar>

            </div>
        </>);
    }
}

export default withStyles(MStyle)(Navbar);
