import React from 'react';

import loader from '../css/image/loader.gif';

import ReactPlayer from 'react-player/youtube'

class Video extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: true
        }

    }

    closeLoader() {
        this.setState({ loader: false });

    }

    componentDidMount() {
        setTimeout(() => {
            this.closeLoader();
        }, 2500);

    }





    render() {
        const { videos } = this.props;
        const { wedding = [], other = [] } = videos || {};

        return (<>

            {this.state.loader ? <>
                <div id="loader">
                    <img src={loader} alt="" width="300px" height="auto" />
                </div>

            </>
                : ""}

            <div className="ff-2 text-secondary mb-5" style={{ visibility: this.state.loader ? "hidden" : "visible" }}>

                <div className="row m-0">
                    <h2 className="col-12 ff-3 text-center mt-5">VIDEOS</h2>
                </div>

                <div className="container mt-5">
                    <h4 className="videoVernagir text-right">Wedding Video</h4>
                </div>

                <div className="container mt-3">
                    <div className="row m-0">
                        {wedding.map((url, index)=>{
                            return <div key={index} className="col-12 col-md-6  p-0">
                                <ReactPlayer url={url} height="225px" width="auto" />
                            </div>
                        })}
                        <div className="col-12 mb-5 mt-5"></div>

                        <div className="col-12 mb-2 mt-2 p-0">
                            <h4 className="videoVernagir text-right">Other Video</h4>
                        </div>
                        {other.map((url, index)=>{
                            return <div key={index} className="col-12 col-md-6  p-0">
                                <ReactPlayer url={url} height="225px" width="auto" />
                            </div>
                        })}

                    </div>

                </div>





            </div>
        </>);
    }
}

export default Video;
